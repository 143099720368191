<template>
  <div>
    <div class="p-grid crud-demo">
      <div class="p-col-12">
        <div class="card layout-toppanel">
          <p>
            <i class="pi pi-fw pi-info-circle"></i> El registro del pago del
            item de T.U.P.A, deben registrarse correctamente siguiendo las
            pautas de casillas obligatorias representadas por (*)
          </p>
        </div>
        <div class="card">
          <Toast />
          <Toolbar class="p-mb-4">
            <template v-slot:left>
              <Button
                :disabled="isBussy"
                label="Guardar"
                icon="pi pi-check"
                class="p-button-info"
                @click="guardar"
            /></template>
            <template v-slot:right>
              <Button
                label="Salir"
                icon="pi pi-arrow-left"
                class="p-button-danger"
                @click="open('list')"
              />
            </template>
          </Toolbar>
          <div class="p-grid p-fluid dashboard">
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Dependencia o sede (*)</h5>
              <Dropdown
                v-model="selectEjecutora"
                required="true"
                :options="ejecutora"
                :filter="true"
                optionLabel="nombre"
                @change="changeDepe()"
                :disabled="isBussy"
                placeholder="Seleccione la sede o dependencia"
                :class="{ 'p-invalid': submitted && !selectEjecutora }"
                filterPlaceholder="Busque la Ejecutora"
              />
              <small class="p-invalid" v-if="submitted && !selectEjecutora"
                >Seleccione la dependencia o sede.</small
              >
            </div>
          </div>
          <div class="p-grid p-fluid dashboard">
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Item del TUPA (*)</h5>
              <Dropdown
                v-model="product.tupa"
                required="true"
                :options="tupaList"
                :filter="true"
                optionLabel="nombre"
                :disabled="isBussy"
                placeholder="Seleccione el item del TUPA"
                :class="{ 'p-invalid': submitted && !product.tupa }"
                filterPlaceholder="Busque el TUPA"
              />
              <small class="p-invalid" v-if="submitted && !product.tupa"
                >Seleccione el item del TUPA.</small
              >
            </div>
          </div>
          <div class="p-grid p-fluid dashboard">
            <div class="p-col-6 p-md-12 p-lg-6">
              <h5>codigo: </h5>
              <InputText
                v-model.trim="product.codigoPago"
                required="false"
                placeholder="código de pago"
                autofocus
                
              />
              
            </div>
            <div class="p-col-6 p-md-12 p-lg-6">
              <h5>RUC de pago: </h5>
              <InputText
                v-model.trim="product.rucPago"
                required="false"
                placeholder="RUC de pago"
                autofocus
               
              />
              
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Tipo de Pago: (*)</h5>
              <label class="form-label">Agente: </label
              ><Checkbox name="agente"  v-model="agente" value = "agente"/>
              <label class="form-label">Banco: </label
              ><Checkbox
                name="banco"
                v-model="banco"
                value = "banco"
              />
            </div>
            <div class="p-col-6 p-md-6 p-lg-6">
              <h5>Monto: </h5>
              <InputText
                v-model.trim="product.monto"
                required="false"
                placeholder="Monto total del TUPA"
                autofocus
               
              />
            </div>
            <div class="p-col-12 p-lg-12">
              <DataTable
                ref="dt"
                v-model:selection="select"
                :value="product.detalle"
                :paginator="true"
                :rows="10"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} multimedias"
                responsiveLayout="scroll"
              >
                <template #header>
                  <div
                    class="
                      table-header
                      p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                    "
                  >
                    <span class="p-m-0">
                      <Button
                        label="Agregar Requerimiento"
                        icon="pi pi-plus"
                        class="p-button-success"
                        @click="openNew()"
                      />
                    </span>
                  </div>
                </template>
                <Column
                  selectionMode="single"
                  headerStyle="width: 3rem"
                ></Column>
                <Column header="Archivo">
                  <template #body="slotProps">
                    <span class="p-column-title">archivo</span>
                    {{ slotProps.data.nombreFile }}
                  </template>
                </Column>
                <Column field="name" header="Descripción" :sortable="true">
                  <template #body="slotProps">
                    <span class="p-column-title">Descripción</span>
                    {{ slotProps.data.titulo }}
                  </template>
                </Column>
                <Column headerStyle="width: 120px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-warning"
                      @click="openDeleteImagen(slotProps.data)"
                    />
                  </template>
                </Column>
              </DataTable>
            </div>
            <Dialog
              v-model:visible="productDialog"
              :style="{ width: '550px' }"
              header="Detalles del Pago"
              :modal="true"
              class="p-fluid"
            >
              <div class="p-field">
                <label for="titulo">Nombre o detalle</label>
                <InputText
                  id="titulo"
                  v-model.trim="detalle.titulo"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted1 && !detalle.titulo }"
                />
                <small class="p-invalid" v-if="submitted1 && !detalle.titulo"
                  >Nombre o detalle es requerido.</small
                >
              </div>

              <div class="p-field">
                <FileUpload
                  mode="basic"
                  :customUpload="true"
                  @uploader="myUploader"
                  :fileLimit="1"
                  :auto="true"
                />
                <label>Archivo: {{ filetitulo }} {{ filetype }}</label>
                <small class="p-invalid" v-if="submitted && !filetitulo">
                  Archivo es requerido.</small
                >
              </div>

              <template #footer>
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  class="p-button-text"
                  @click="hideDialog"
                />
                <Button
                  label="Agregar"
                  icon="pi pi-check"
                  class="p-button-text"
                  @click="saveProduct"
                />
              </template>
            </Dialog>
             <Dialog
                v-model:visible="deleteDialogoImagen"
                :style="{ width: '450px' }"
                header="Confirm"
                :modal="true"
              >
                <div class="confirmation-content">
                  <i
                    class="pi pi-exclamation-triangle p-mr-3"
                    style="font-size: 2rem"
                  />
                  <span v-if="detalle"
                    >Está seguro de borrar este item: <b>{{ detalle.titulo }}</b
                    >?</span
                  >
                </div>
                <template #footer>
                  <Button
                    label="No"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="deleteDialogoImagen = false"
                  />
                  <Button
                    label="Si"
                    icon="pi pi-check"
                    class="p-button-text"
                    @click="deleteImagen"
                  />
                </template>
              </Dialog>
            <!--<div class="p-col-12 p-md-12 p-lg-12">
              <h5>Detalle en PDF (*)</h5>
              <FileUpload
                mode="basic"
                :customUpload="true"
                @uploader="myUploader"
                :fileLimit="1"
                :auto="true"
              />
              <label>Archivo: {{ filetitulo }} {{ filetype }}</label>
              <small class="p-invalid" v-if="submitted && !filetitulo">
                Archivo es requerido.</small>
            </div>
            
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Observación/Objeto</h5>
              <Textarea
                v-model="product.descripcion"
                required="true"
                rows="3"
                cols="20"
              />
            </div>
            <div class="p-col-3 p-md-3 p-lg-3">
              <h5>Nro. de Orden</h5>
              <InputNumber
                v-model="product.orden"
                showButtons
                buttonLayout="horizontal"
                decrementButtonClass="p-button-warning"
                incrementButtonClass="p-button-info"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    this.action = "gstupa/PAGO_SAVE";
    return {
      isBussy: false,
      banco: false,
      agente: false,
      submitted: false,
      submitted1: false,
      product: {},
      filetitulo: "",
      filetype: "",
      ejecutora: null,
      tupaList: null,
      products: [],
      selectEjecutora : {},
      productDialog: false,
      deleteDialogoImagen: false,
      detalle: {},
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  methods: {
    openDeleteImagen(product) {
      this.detalle = product;
      this.deleteDialogoImagen = true;
    },
    deleteImagen() {
      this.product.detalle = this.product.detalle.filter(
        (val) => val.ruta !== this.detalle.ruta
      );

      this.deleteDialogoImagen = false;
      this.detalle = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Item borrado",
        life: 3000,
      });
    },
    saveProduct() {
      this.submitted1 = true;
      if (this.detalle.titulo.trim() && this.filetitulo.trim()) {
        this.product.detalle.push(this.detalle);
        this.productDialog = false;
      }
    },
    myUploader(file) {
      this.$swal({
        width: 370,
        text: "Subiendo archivo esperar porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.detalle.nombreFile = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.detalle.mineType = file.files[0].type;
              this.detalle.ruta = res.destino;
              
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    getFileName(e) {
      let name = e.split(/[\\/]/g).pop().split(".")[0];
      let extension = "";
      if (e.includes(".")) {
        extension = "." + e.split(/[\\/]/g).pop().split(".")[1];
      }
      return name + extension;
    },
    openNew() {
      this.filetitulo = "";
      this.filetype = "";
      this.detalle = {
        id: "0",
        fechaHora: "",
        ruta: "",
        mineType: "",
        tamanio: "",
        extension: "",
        estado: "",
        pago: {},
      };
      this.submitted1 = false;
      this.productDialog = true;
    },
    changeDepe() {
      this.listarTupa(this.selectEjecutora.idEjecutora);
    },
    guardar() {
      this.submitted = true;
      if(this.product.tupa ){
      
      let modelfinal = {
        codigoPago: this.product.codigoPago,
        estado : false,
        monto: this.product.monto,
        rucPago: this.product.rucPago,
        tipoPagoAgente: this.agente.length > 0 ? true : false,
        tipoPagoBanco:  this.banco.length > 0 ? true : false,
        tupa : {
          id: this.product.tupa.id
        },
        detalle: []
      }

      this.product.detalle.forEach(function(element) {
            let new_ = {
              extension: "",
              mineType: element.mineType,
              nombreFile : element.nombreFile,
              ruta : element.ruta,
              tamanio : element.tamanio,
              titulo: element.titulo
            }
            modelfinal.detalle.push(new_)
          });
      

           this.$swal({
            width: 370,
            text: "Cargando...",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: () => {
              this.$swal.showLoading();
              this.$store
                .dispatch(this.action, modelfinal)
                .then((res) => {
                  this.$swal.close();
                  this.open("list");
                  this.$toast.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "item pagado: " + res.id,
                    life: 3000,
                  });
                })
                .catch((error) => {
                  this.$swal.close();
                  if (
                    error.response.status == 401 ||
                    error.response.status == 500
                  ) {
                    this.$swal(
                      error.response.data.errorMessage,
                      "Ud. no tiene permitido esta función",
                      "warning"
                    );
                  } else if(error.response.status == 409) {
                    this.$swal(
                      error.response.data.errorMessage,
                      "Este item del tupa ya está configurado",
                      "warning"
                    );
                  }
                });
            },
          }).then((result) => {
            console.log(result);
          });
      }
      
    },

    listarEjecutora(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gstupa/DEPENDENCIA_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.ejecutora = res;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },

    listarTupa(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gstupa/TUPA_LIST_DEPE", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.tupaList = res;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },

    open(operacion) {
      let mutacion = {
        oper: operacion,
        pago: {
          tupa : {},
          estado: true,
          detalle:[]
        },
      };

      this.$router.push({
        name: "tupa/pago",
      });
      this.$store.commit("gstupa/OPERACION_PAGO", mutacion);
    },
    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },
  },

  mounted() {
    if (this.pago.estado == false) {
      this.$router.push({
        name: "tupa/pago",
      });
    }
    this.listarEjecutora();
    this.product = this.pago;
    console.info(this.pago);
  },

  computed: {
    ...mapGetters({ pago: "gstupa/getModelPago" }),
  },
};
</script>
<style>
</style>